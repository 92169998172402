<template>
  <div v-if="showConclusion" class="badge" :class="resultStatusClass">
    {{ statusName }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType, Result } from "@/types";

export default defineComponent({
  name: "ResultConclusion",
  props: {
    result: {
      required: true,
      type: Object as PropType<Result>,
    }
  },
  computed: {
    resultStatusClass() {
      return {
        pending: `text-bg-info`,
        not_passed: `text-bg-danger`,
        passed: `text-bg-success`,
      }[this.result.status];
    },
    showConclusion() {
      return ["passed", "not_passed"].includes(this.result.status);
    },
    statusName() {
      switch (this.result.status) {
        case "pending": return this.$t("js.result.conclusion.pending");
        case "passed": return this.$t("js.result.conclusion.passed");
        case "not_passed": return this.$t("js.result.conclusion.not_passed");
      };
    },
  },
});
</script>
