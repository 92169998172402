<template>
  <div class="text-center">
    <h5 v-if="label">{{ label }}</h5>
    <div class="progress position-relative">
      <div class="progress-bar" role="progressbar" :style="{ width: `${percent}%` }"
        :aria-valuenow="count" aria-valuemin="0" :aria-valuemax="total">
        {{ count }} / {{ total }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    count: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    percent() {
      return this.count * 100.0 / Math.max(this.total, 1);
    },
  },
});
</script>
