<template>
  <span :id="countId" v-text="tweenedCount.toFixed(0)" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { gsap } from "gsap";

export default defineComponent({
  name: "TweenedCount",
  props: {
    id: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 1000,
    },
  },
  data: () => ({
    tweenedCount: 0,
  }),
  computed: {
    countId() {
      return `${this.id}_count`;
    },
  },
  watch: {
    count: function (newCount) {
      gsap.to(`#${this.id}_count`, { innerText: newCount.toFixed(0), duration: 1.0, snap: { innerText: 1 }, });
    },
  },
  mounted() {
    this.tweenedCount = this.count;
  },
});
</script>
