<template>
  <transition name="modal" tag="div">
    <div v-if="visible" class="d-block modal my-modal-backdrop show fade" :class="{ 'modal-static': closeDenied }"
      @click.self="tryClose">
      <div class="modal-dialog modal-dialog-scrollable" :style="`max-width: ${maxWidth}`">
        <div class="modal-content" ref="modalContent">
          <header class="modal-header d-flex justify-content-between" v-if="$slots.header">
            <slot name="header"></slot>
          </header>
          <slot name="outerBody">
            <main class="modal-body" v-if="$slots.body">
              <slot name="body"></slot>
            </main>
          </slot>
          <footer class="modal-footer" v-if="$slots.footer || closable" style="display:block">
            <slot name="footer">
              <button v-if="closable" type="button" class="btn btn-primary" @click.prevent="tryClose">{{
      $t('js.actions.close') }}</button>
            </slot>
          </footer>
        </div>
      </div>
    </div>
  </transition>

</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Modal",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "500px",
    },
  },
  data: () => ({
    closeDenied: false,
  }),
  methods: {
    tryClose() {
      if (this.closable) this.$emit("close");
      else {
        this.closeDenied = true;
        setTimeout(() => { this.closeDenied = false }, 250);
      }
    }
  },
  watch: {
    showModal: function (value) {
      if (value) this.$emit("opened");
      else this.$emit("closed");
    },
  },
});
</script>

<style>
.my-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0007;
  backdrop-filter: blur(4px);
  z-index: 1000;
}

.modal-enter-active {
  animation: modalEnter 250ms;
}

.modal-leave-active {
  animation: modalLeave 250ms;
}

@keyframes modalLeave {
  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.0;
  }
}

@keyframes modalEnter {
  from {
    opacity: 0.0;
  }

  to {
    opacity: 1.0;
  }
}
</style>