<template>
  <div>
    <div v-if="post.type === 'Comment'">
      <div class="d-inline-block rounded p-2 bg-theme">
        {{ post.message }}
      </div><br>
      <span class="small text-black-50">{{ post.owner.name }} - {{ post.created_at }}</span>
    </div>
    <div v-else-if="post.type === 'Amendment'">
      <div style="border: 1px solid #9B9F9B; border-radius: 5px">
        <div class="p-3 bg-theme">
          <h5>
            <i class="fas fa-file-signature"></i>
            {{ post.target_slide.deleted
              ? $t("js.sidebar.general.deleted", { resource: $t("js.sidebar.general.resource.slide") })
              : post.target_slide.title[firstAvailableLocale]
            }}
          </h5>
          <span>{{ $t("js.sidebar.general.by") }} {{ post.owner.name }}</span>
        </div>
        <div class="p-3" style="white-space: pre-wrap; overflow-wrap: break-word;">{{ post.message }}</div>
      </div>
    </div>
    <div v-else-if="post.type === 'Candidacy'">
      <div style="border: 1px solid #9B9F9B; border-radius: 5px">
        <div class="p-3 bg-theme">
          <h5><i class="fas fa-user"></i> {{ post.owner.name }}</h5>
          <span>
            {{ $t("js.sidebar.general.for") }}
            {{ post.target_slide.deleted
              ? $t("js.sidebar.general.deleted", { resource: $t("js.sidebar.general.resource.slide") })
              : post.target_slide.title[firstAvailableLocale]
            }}
          </span>
        </div>
        <div class="p-3" style="white-space: pre-wrap; overflow-wrap: break-word;">{{ post.message }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { useSharedStore } from "@/entrypoints/stores/shared";
import type { PropType, UserPost } from "@/types";

export default defineComponent({
  name: "PresentPost",
  props: {
    post: {
      type: Object as PropType<UserPost>,
      required: true,
    },
  },
  computed: {
    ...mapState(useSharedStore, ["firstAvailableLocale"]),
  },
});
</script>
