<template>
  <div class="px-3 px-md-5 py-1 bg-theme"
    :class="{ 'progress-bar-striped progress-bar-animated': ballotStateIn('calculating_result') }">
    <span v-if="ballotSlide.disableAt && ballotStateIn(['open', 'countdown'])"
      class="float-end ms-3 text-monospace lead pb-sm-0">
      <b class="mb-0">
        <Countdown :end-time="Number(ballotSlide.disableAt)" @timesUp="timesUp = true" />
      </b>
    </span>
    <p class="lead mb-0">
      {{ closing ? $t("js.ballot.status.closing") : $t(`js.ballot.status.${ballotSlide.state}`) }}
      <span v-if="!canVote && !ballotStateIn(['calculating_result', 'finished'])"><br>
        {{ $t("js.ballot.cant_vote") }}
      </span>
    </p>
    <ResultProgress v-if="ballotStateIn(['calculating_result', 'finished']) && votingRoundReport"
      :progress="votingRoundReport.status" />
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from "pinia";
import Countdown from "../Countdown.vue";
import ResultProgress from "../ResultProgress.vue";
import { defineComponent } from "vue";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import type { PropType, Slide, ConferenceVotingRoundReport, BallotStates } from "@/types";

export default defineComponent({
  name: "BallotStatus",
  props: {
    voted: {
      type: Boolean,
      default: false,
    },
    canVote: {
      type: Boolean,
      default: true,
    },
    ballotSlide: {
      type: Object as PropType<Slide>,
      default() {
        return {}
      },
    },
  },
  data: () => ({
    showInfo: false,
    timesUp: false,
  }),
  components: {
    ResultProgress,
    Countdown,
  },
  computed: {
    ...mapState(usePresentationStore, ["votingRoundReports"]),
    votingRoundReport() {
      return this.votingRoundReports?.find((votingRoundReport: ConferenceVotingRoundReport) =>
        votingRoundReport.votingRoundReference === this.ballotSlide.votingRoundReference);
    },
    closing() {
      if (this.ballotSlide.state === "countdown" && this.timesUp) {
        return true;
      } else {
        this.timesUp = false;
        return false;
      }
    },
  },
  methods: {
    ...mapActions(usePresentationStore, ["updateStatus"]),
    ballotStateIn(ballotStates: BallotStates | BallotStates[]) {
      if (this.ballotSlide) {
        if (Array.isArray(ballotStates)) {
          return ballotStates.includes(this.ballotSlide.state);
        } else {
          return ballotStates === this.ballotSlide.state;
        }
      }
      return false;
    },
  },
})
</script>

<style scoped>
.progress-bar-animated {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #1226AA;
  --bs-progress-bar-transition: width 0.6s ease;
}
</style>
