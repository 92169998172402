<template>
  <div class="result container-fluid p-0 mb-4">
    <template v-if="result && (published || alwaysShowResult) && hasResultData">
      <div class="clearfix">
        <ResultConclusion :result="result" class="float-end" />
      </div>

      <h4 v-if="header" class="contest-title">
        {{ $t("js.result.header") + (header && ` - ${header}`) }}
      </h4>
      <h5 v-if="contest.question[$i18n.locale]" class="contest-question">
        {{ contest.question[$i18n.locale] }}
      </h5>
      <template v-if="contest.ruleSet !== 'ranked'">
        <NormalSummary :result="result" :contest="contest" :key="contest.reference" :theme="theme"
          :locale="$i18n.locale" />
      </template>
      <template v-else-if="contest.ruleSet === 'ranked' && contest.resultMethod === 'prioritization'">
        <RankedSummary :result="result" :contest="contest" :key="contest.reference" :theme="theme"
          :locale="$i18n.locale" />
      </template>
      <template v-else-if="contest.ruleSet === 'ranked' && isInstantRunoff">
        <InstantRunoffSummary v-for="seat, index in seatsData" :result="seat" :contest="contest"
          :key="contest.reference" :locale="$i18n.locale" :resultMethod="contest.resultMethod"
          :seatNumber="index + 1" :votesNotIncluded="votesNotIncluded(index)" :theme="theme"/>
      </template>
      <template v-else-if="contest.ruleSet === 'ranked'">
        <NormalSummary :result="result" :contest="contest" :key="contest.reference"
          :locale="$i18n.locale" :theme="theme" />
      </template>
      <template v-else-if="result.htmlSummary">
        <div v-html="result.htmlSummary" class="w-100"></div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType, ConferenceContest, Result, Theme } from "@/types";
import Option from "./Option.vue";
import RankedSummary from "@/components/shared/RankedSummary.vue";
import NormalSummary from "@/components/shared/NormalSummary.vue";
import ResultConclusion from "./ResultConclusion.vue";
import InstantRunoffSummary from "@/components/shared/InstantRunoffSummary.vue";

export default defineComponent({
  components: { ResultConclusion, NormalSummary, RankedSummary, Option, InstantRunoffSummary },
  props: {
    header: {
      type: String,
      default: null,
    },
    alwaysShowResult: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object as PropType<Result>,
      required: true,
    },
    published: {
      type: Boolean,
      default: false,
    },
    contest: {
      type: Object as PropType<ConferenceContest>,
      required: true,
    },
    themeColor: {
      type: String,
      default: null,
    },
    theme: {
      type: String as PropType<Theme>,
      default: "light",
    },
  },
  computed: {
    hasResultData() {
      return !!this.result.resultData;
    },
    seatsData() {
      if (this.contest.resultMethod === "instant_runoff") return [this.result.resultData];
      else return this.result.resultData.seatsData;
    },
    isInstantRunoff() {
      return ["instant_runoff", "multi_seat_instant_runoff"].includes(this.contest.resultMethod);
    },
    votesNotIncluded() {
      return (index: number) => {
        if (this.contest.resultMethod === "multi_seat_instant_runoff") {
          const totals = [];
          this.result.resultData.seatsData.map((seatResult: any) =>
            totals.push(seatResult.totalVotes));
          if (index > 0) return totals[index - 1] - totals[index];
          else return null;
        }
        return null;
      }
    },
  },
});
</script>

<style>
.result {
  padding: 0.5em;
  box-sizing: border-box;
}

.contest-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39);
  font-weight: 500;
}

.contest-description {
  margin-bottom: 1rem;
  color: rgb(17 24 39);
}

.contest-question {
  font-size: 1.25rem;
  margin-top: 1.5rem;
}
</style>
