<template>
  <div class="text-center">
    <h5 v-if="showLabel">{{ $t("js.ballot.voting_in_progress") }}</h5>
    <div v-if="election.voterCountsConfig.enabled" class="progress position-relative">
      <div v-if="count > 0" class="progress-bar overflow-hidden" role="progressbar"
        :style="{ width: `${percent}%` }" :aria-valuenow="count" :aria-valuemax="total">
        {{ count }} / {{ total }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import type { PropType, ConferenceContest } from "@/types";

export default defineComponent({
  props: {
    ballot: {
      type: Object as PropType<ConferenceContest>,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(useSharedStore, ["election"]),
    ...mapState(usePresentationStore, ["voterCounts"]),
    count() {
      if (!this.election.voterCountsConfig) return null;
      switch (this.election.voterCountsConfig.progressBarShows) {
        case "vote_count_of_total_eligibles":
        case "vote_count_of_active_eligibles":
          return this.ballot.voteCount;
        case "vote_weight_of_total_eligibles":
        case "vote_weight_of_active_eligibles":
          return this.ballot.votesCombinedWeight;
      }
      return null;
    },
    total() {
      if (!this.election.voterCountsConfig) return null;
      let totalCount = null;
      switch (this.election.voterCountsConfig.progressBarShows) {
        case "vote_count_of_total_eligibles":
          totalCount = this.shownVoterCounts.totalEligibles;
          break;
        case "vote_weight_of_total_eligibles":
          totalCount = this.shownVoterCounts.totalEligiblesWeight;
          break;
        case "vote_count_of_active_eligibles":
          totalCount = this.shownVoterCounts.activeEligiblesOrVoted;
          break;
        case "vote_weight_of_active_eligibles":
          totalCount = this.shownVoterCounts.activeEligiblesOrVotedWeight;
          break;
      }
      if (this.count && totalCount) return Math.max(this.count, totalCount);
      else return totalCount;
    },
    percent() {
      if (this.count != null && this.total != null)
        return this.count * 100.0 / Math.max(this.total, 1);
      else
        return null;
    },
    shownVoterCounts() {
      if (this.ballot.result && this.ballot.result.voterCounts) {
        return this.ballot.result.voterCounts;
      } else if (["closed", "finished"].includes(this.ballot.state) && this.ballot.voterCountsOnClose) {
        return this.ballot.voterCountsOnClose;
      } else {
        return this.voterCounts;
      }
    },
  },
});
</script>
