<template>
  <div class="px-3 px-md-5 py-1" :class="{ 'bg-theme-danger': showErrors, 'bg-light': !showErrors }">
    <div v-if="errors">
      <div class="sub-lead" v-for="error in errors">
        {{ $t(error.path, error.values) }}
      </div>
    </div>
    <div v-if="!contest.disregardVoterWeight" class="sub-lead">
      {{ $tc("js.ballot.your_vote_weight", voter.weight) }}
    </div>
    <div class="d-flex justify-content-between flex-wrap">
      <span class="sub-lead">{{ crossCounter }}</span>
      <span class="sub-lead">
        {{ $t("js.ballot.selected") }}: {{ selected }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from "pinia"
import SelectionPileValidator from "@aion-dk/js-client/dist/lib/validators/selectionPileValidator";
import BelgiumBallotValidator from "@aion-dk/js-client/dist/lib/validators/belgiumBallotValidator";
import { useVotingSessionStore } from "@/entrypoints/stores/voting_session";
import type { PropType, SelectionPile, ContestError } from "@/types";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BallotStatus",
  props: {
    contest: {
      type: Object,
      required: true,
    },
    activeSelectionPile: {
      type: Object as PropType<SelectionPile>,
      required: true,
    },
    includeLazyErrors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useVotingSessionStore, ["voter"]),
    crossCounter() {
      let counter = null;
      const minimumVotes = this.contest.markingType?.minMarks;
      const maximumVotes = this.contest.markingType?.maxMarks;
      const voteVariation = this.contest.markingType?.voteVariation;
      if (voteVariation === "ranked") {
        counter = minimumVotes === maximumVotes
          ? this.$tc("js.ballot.rank_x_options", minimumVotes)
          : this.$t("js.ballot.rank_between_x_and_y_options", { x: minimumVotes, y: maximumVotes });
      } else {
        counter = minimumVotes === maximumVotes
          ? this.$tc("js.ballot.choose_x_options", minimumVotes)
          : this.$t("js.ballot.choose_between_x_and_y_options", { x: minimumVotes, y: maximumVotes });
      }
      return counter;
    },
    selected() {
      if (!this.activeSelectionPile) return 0;
      return this.activeSelectionPile.optionSelections.length + (this.activeSelectionPile.explicitBlank && 1);
    },
    errors() {
      if (!this.activeSelectionPile) return [];
      const customvalidators: any[] = [];

      const errors = new SelectionPileValidator(this.contest).validate(this.activeSelectionPile, this.includeLazyErrors).map((error: ContestError) => {
        if (error.keys) {
          // We do this in case a key contains a locale string
          Object.keys(error.keys).forEach((key) => {
            if (typeof error.keys[key] === "object") {
              error.keys[key] = error.keys[key][this.$i18n.locale];
            }
          });
        }

        switch (error.message) {
          case "blank":
            return {
              path: "js.error.default.blank_combined_with_other_options",
              values: {},
            }
          case "too_many":
            return {
              path: "js.error.default.too_many_crosses",
              values: {
                maximum: this.contest.markingType?.maxMarks || this.contest.maximumVotes
              },
            }
          case "exceeded_list_limit":
            return {
              path: "js.components.AVSubmissionHelper.errors.exceeded_list_limit",
              values: error.keys,
            }
          case "below_list_limit":
            return {
              path: "js.components.AVSubmissionHelper.errors.below_list_limit",
              values: error.keys,
            }
        }
        return {
          path: `js.error.${this.contest.markingType?.voteVariation || this.contest.ruleSet}.${error.message}`,
          values: [],
        }
      });

      if (this.contest.customRulesets.includes("belgian_ballot_rules")) {
        customvalidators.push(new BelgiumBallotValidator(this.contest));
      }

      const customErrors = customvalidators.flatMap(validator => {
        return validator.validate(this.activeSelectionPile).map((error: ContestError) => {
          return {
            path: `js.components.AVSubmissionHelper.errors.${error.message}`,
            values: [],
          }
        });
      });

      const combinedErrors = [...errors, ...customErrors];

      return [...combinedErrors];
    },
    showErrors() {
      return this.errors.length > 0;
    },
  },
});
</script>
