<template>
  <hr />
  <div class="d-flex align-items-center justify-content-between ">
    <h3 class="contest-title">
      {{ title }}
    </h3>
    <div v-show="!isObserver">
      <span v-if="showOptions" class="badge bg-primary">
        {{ $tc("js.ballot.option_counter", contest.optionsCount) }}
      </span>
      <span v-else :class="`badge bg-${isEligible ? 'success' : 'danger'}`">
        {{ isEligible ? $t("js.ballot.eligible") : $t("js.ballot.ineligible") }}
      </span>
    </div>
  </div>
  <div
    v-if="description && showDescription"
    class="contest-description"
    v-html="description"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { flattenOptions } from "@/entrypoints/shared/contest_utilities";
import type { PropType, ConferenceContest, Locale } from "@/types";

export default defineComponent({
  name: "ContestHeader",
  props: {
    contest: {
      type: Object as PropType<ConferenceContest>,
      required: true,
    },
    locale: {
      type: String as PropType<Locale>,
      default: "en",
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    isEligible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.contest.title[this.locale];
    },
    description() {
      return this.contest.description[this.locale];
    },
    isObserver() {
      return window.location.href.search(/\observe\b/) >= 0;
    },
  },
  methods: {
    flattenOptions,
  },
});
</script>

<style lang="scss" scoped>
.contest-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39);
  font-weight: 500;
}

.contest-description {
  margin-bottom: 0;
  color: rgb(17 24 39);
}
</style>
