<template>
  <div class="mb-n3 mb-md-n5" :class="{ 'h-100': forceFullSizeSlide }">
    <slot name="before">
      <!--content before sticky goes here (sticky will be stuck to bottom of viewport) -->
    </slot>
    <div v-show="stickies" class="my-sticky-bottom mt-3 mt-md-5 mx-n3 mx-md-n5 sticky-shadow"
      :class="{ 'shadow-sm': !isSticky }">
      <template v-for="sticky in stickies">
        <BallotStatus v-if="sticky.type === 'ballotStatus'" :voted="sticky.voted" :can-vote="sticky.canVote"
          :ballot-slide="sticky.ballot" />
        <ContestStatus v-else-if="sticky.type === 'contestStatus'" :contest="sticky.contest"
          :active-selection-pile="sticky.activeSelectionPile" :includeLazyErrors="sticky.includeLazyErrors" />
        <div v-else class="text-sm-center px-3 py-2 bg-theme" :class="sticky.class">
          <span class="sub-lead">{{ sticky.message }}</span>
        </div>
      </template>
    </div>
    <a id="sticky-anchor" ref="stickyAnchor"></a>
    <slot name="after">
      <!-- content after sticky goes here (sticky will scroll out of view with the content in "before" slot -->
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import BallotStatus from "./sticky_alerts/BallotStatus.vue";
import ContestStatus from "./sticky_alerts/ContestStatus.vue";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import type { Subscriber, Sticky } from "@/types";

export default defineComponent({
  name: "StickyAlerts",
  components: {
    BallotStatus,
    ContestStatus,
  },
  data() {
    return {
      subscribers: [],
      isSticky: false
    }
  },
  methods: {
    ...mapActions(usePresentationStore, ["updateStatus"]),
    stickyAlertsSubscribe(component: Subscriber) {
      this.subscribers.push(component);
    },
    stickyAlertsUnsubscribe(component: Subscriber) {
      const subscriberIndex = this.subscribers.findIndex((subscriber: Subscriber) =>
        subscriber === component);
      this.subscribers.splice(subscriberIndex, 1);
    },
  },
  computed: {
    ...mapState(usePresentationStore, ["forceFullSizeSlide"]),
    stickies() {
      const alerts = [];
      this.subscribers.forEach((subscriber: Subscriber) =>
        subscriber.stickies.forEach((alert: Sticky) => alerts.push(alert)));
      return alerts;
    },
  },
  provide: function () {
    return {
      stickyAlertsSubscribe: this.stickyAlertsSubscribe,
      stickyAlertsUnsubscribe: this.stickyAlertsUnsubscribe,
    }
  },
  mounted: function () {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.0,
    }
    const component = this;
    new IntersectionObserver(function (entries, _observer) {
      component.isSticky = !entries[0].isIntersecting;
    }, options).observe(this.$refs.stickyAnchor);
  },
});
</script>
