<template>
  <AVRankedSummary
    :result="result.resultDataForDisplay"
    :rounds="rounds"
    :distribution-number="distributionNo"
    :seats="contest.seats"
    :theme="theme"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Option from "../frontend/shared/Option.vue";
import { flattenOptions } from "@/entrypoints/shared/contest_utilities";
import type { ConferenceContest, PropType, Result, ConferenceOption, ConferenceVotingRound, Theme } from "@/types";

export default defineComponent({
  name: "RankedSummary",
  components: { Option },
  props: {
    result: {
      type: Object as PropType<Result>,
      required: true,
    },
    contest: {
      type: Object as PropType<ConferenceContest>,
      required: true,
    },
    theme: {
      type: String as PropType<Theme>,
      default: "dark",
    },
  },
  computed: {
    rounds() {
      return this.result.resultData.rounds;
    },
    elected() {
      return this.result.resultData.elected;
    },
    tied() {
      return this.result.resultData.tied;
    },
    distributionNo() {
      return this.result.resultData.distributionNo;
    },
    options() {
      return this.flattenOptions(this.contest.options);
    },
    sortedOptions() {
      return [...this.options].sort((o1, o2) => o1.reference.localeCompare(o2.reference)).sort(this.compareOptions);
    },
    sortedResult() {
      const sortedResults: any[] = [];

      this.sortedOptions.forEach((so: ConferenceOption) => {
        const option: any = {
          reference: so.reference,
          title: so.title,
          elected: this.isElected(so),
          tied: this.isTied(so),
          optionPosition: this.optionPosition(so)
        };
        if (so.image) option.image = so.image;
        sortedResults.push(option);
      });

      return sortedResults;
    },
  },
  methods: {
    flattenOptions,
    finalRound(reference: string) {
      const electedIndex = this.rounds.findIndex((round: ConferenceVotingRound) =>
        round.elected.includes(reference));
      const lastIndex = this.rounds.length - 1;
      return electedIndex !== -1 ? electedIndex : lastIndex;
    },
    countForRound(reference: string, roundIndex: number) {
      if (this.finalRound(reference) >= roundIndex || this.finalRound(reference) === -1)
        return this.rounds[roundIndex].accumulatedCounts[reference];
      else
        return "";
    },
    optionPosition(option: ConferenceOption) {
      if (this.elected.includes(option.reference))
        return this.sortedOptions.indexOf(option) + 1;
      else
        return "-";
    },
    isElected(option: ConferenceOption) {
      return this.elected.includes(option.reference);
    },
    isTied(option: ConferenceOption) {
      if (this.isElected(option)) return false;
      return this.tied.includes(option.reference);
    },
    compareOptions(option1: ConferenceOption, option2: ConferenceOption) {
      const relevantRound = Math.min(this.finalRound(option1.reference), this.finalRound(option2.reference));
      const sorting = this.countForRound(option1.reference, relevantRound) - this.countForRound(option2.reference, relevantRound);
      // If two options have the same sorting score and they aren't both elected,
      // sort the elected option before the unelected option
      if (sorting === 0 && this.isElected(option1) !== this.isElected(option2))
        return this.isElected(option1) ? -1 : 1;
      else
        return -1 * sorting; // Multiplied by -1 to sort in descending order
    },
  },
});
</script>
