// Set the name of the hidden property and the change event for visibility
import {reactive} from "vue";

const visibility = reactive({
    visible: true
});

// Handle page visibility change
document.addEventListener("visibilitychange", function () {
    visibility.visible = !document.hidden;
}, false);

export default visibility