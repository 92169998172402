<template>
  <span>
    {{ textTime }}
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { useTimingStore } from "@/entrypoints/stores/timing";

export default defineComponent({
  name: "Countdown",
  props: {
    endTime: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      timerObject: null,
      timeLeft: 0,
    }
  },
  methods: {
    setTimer() {
      clearInterval(this.timerObject);
      const offset = this.offsetTime - Date.now(); // Date.now() is in utc
      this.timeLeft = Math.trunc(offset / 1000);
      this.timerObject = setInterval(() => {
        let offset = this.offsetTime - Date.now(); // Date.now() is in utc
        if (offset <= 0 && !this.lastCheckAt) offset = 1; // in case the offset is fairly large, don't trigger timesUp until the offset has been determined
        if (offset < 0) {
          clearInterval(this.timerObject);
          this.$emit("timesUp");
          this.timeLeft = 0;
        } else {
          this.timeLeft = Math.trunc(offset / 1000);
        }
      }, 1000);
    },
  },
  computed: {
    ...mapState(useTimingStore, ["lastCheckAt", "timeOffset"]),
    offsetTime() {
      if (this.lastCheckAt != null) return this.endTime + this.timeOffset;
      else return null;
    },
    minutes() {
      return Math.trunc(this.timeLeft / 60);
    },
    seconds() {
      return this.timeLeft % 60;
    },
    textTime() {
      return this.minutes < 0 ? this.seconds : `${this.minutes}:${this.seconds.toString().padStart(2, '0')}`;
    },
  },
  watch: {
    offsetTime: function (newVal) {
      if (newVal != null)
        this.setTimer();
    },
  },
  created() {
    this.setTimer();
  },
});
</script>
